.invest {
  display: flex;
  align-items: center;
  width: 100%;
  font-family: "Neue Montreal", Helvetica, Arial, sans-serif;
  letter-spacing: 0.05rem;
  line-height: 1.5;
}

.invest__box {
  margin: 40px 0;
  display: flex;
  width: 95%;
  margin: 40px auto;
}

.invest__main {
  display: flex;
  align-items: center;
  width: 50%;
  font-size: 34px;
  font-weight: 700;
  margin: 0 30px 0 0;
}

.invest__deskription {
  width: 50%;
  font-size: 28px;
}

.invest__major {
  margin: 0 0 15px 0;
}

.invest__c {
  text-align: center;
  margin: 8px 0;
  font-weight: 700;
  color: rgb(245, 182, 89);
}


@media screen and (max-width: 900px) {
  .invest__box {
    flex-direction: column;
  }

  .invest__main {
    justify-content: center;
    width: 100%;
    margin: 30px 0;
    font-size: 31px;
  }

  .invest__deskription {
    margin-bottom: 30px;
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .invest__main, 
  .invest__deskription, 
  .invest__c  {
    font-size: 22px;
  }
}