.button__buy {
  width: 130px;
  padding: 9px 25px;
  font-family: "Neue Montreal", Helvetica, Arial, sans-serif;
  color: #faf9f9;
  text-decoration: none;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  background-color: rgb(245, 151, 12);
  border-radius: 3px;
  transition-duration: 0.4s;
  &:hover {
    background: rgba(175, 176, 179, 0.6);
  }
}

.button__buy__no {
  width: 130px;
  padding: 9px 25px;
  font-family: "Neue Montreal", Helvetica, Arial, sans-serif;
  color: #faf9f9;
  text-decoration: none;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  border-radius: 3px;
  background-color:rgba(175, 176, 179, 0.6);;
  &:hover {
    cursor: auto;
  }
}