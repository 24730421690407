/*page loading*/
#preloader-active {
    display: block;
}
.preloader {
    background-color: #f7f7f7;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        max-width: 250px;
    } 
}

.text-center {
    justify-content: center;
    align-items: center;
}

@-webkit-keyframes load {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }

    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

@keyframes load {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }

    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

.loader {
    border-radius: 50%;
    width: 2em;
    height: 2em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load 1.8s infinite ease-in-out;
    animation: load 1.8s infinite ease-in-out;
    color: rgb(18, 70, 70);
    font-size: 10px;
    margin: 80px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;

    &:before {
        border-radius: 50%;
        width: 2em;
        height: 2em;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation: load 1.8s infinite ease-in-out;
        animation: load 1.8s infinite ease-in-out;
        content: '';
        position: absolute;
        top: 0;
        left: -3.5em;
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    &:after {
        border-radius: 50%;
        width: 2em;
        height: 2em;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation: load 1.8s infinite ease-in-out;
        animation: load 1.8s infinite ease-in-out;
        content: '';
        position: absolute;
        top: 0;
        left: 3.5em;
    }
}


