.about__tok {
  background-color: rgb(255, 255, 255);
  font-family: "Neue Montreal", Helvetica, Arial, sans-serif;
  padding: 0 0 100px 0;
}

.about__tok__box {
  margin: 40px auto;
  width: 75%;
}

.box__text{
  width: 50%;
}

.kvar {
  font-size: 32px;
  letter-spacing: 0.04rem;
  color:rgb(245, 151, 12);
  margin: 0;
  padding: 0;
}

.description_token,
.short__about {
  margin: 15px 0 0 0;
  letter-spacing: 0.04rem;
  color: #857f7f;
  font-size: 20px;
  line-height: 1.5;
}

.name__token__link {
  display: flex;
  justify-content: center;
  margin: 100px 0 0px 0;
  border-top: 1px solid rgb(173, 173, 173);
  border-bottom: 1px solid rgb(173, 173, 173);
}

.id__box {
  font-size: 22px;
  color: #857f7f;
  letter-spacing: 0.04rem;
  margin: 20px 20px 0 20px;
  padding: 0 5px 11px 5px;
  cursor: pointer;
  border-bottom: 2px solid  rgba(201, 201, 201, 0.1);

  &:hover {
    border-bottom: 2px solid #867a7a;
  }
}

.about__img {
  width: 50%;
}

.about__three__tokens {
  display: flex;
  margin-top: 70px;
}

.buy {
  margin: 60px 0 20px 0;
}

.about__img  {
  display: flex;
  padding: 0 60px 0 0;

  & img{
    max-width: 90%;
    max-height: 90%;
    border-radius: 10px;
    object-fit: cover;
  }
}

@media screen and (max-width: 1000px) {
  .about__three__tokens {
    flex-direction: column;
  }

  .about__img { 
    justify-content: center;
    width: 100%;
    padding: 0;
    & img {
      width: 100%;
      border-radius: 20px;
    }
  }

  .box__text{
    width: 100%;
    text-align: center;
  }

  .kvar {
    margin: 50px 0 30px 0;
  }
}

@media screen and (max-width: 500px) {
  .name__token__link {
    flex-direction: column;
    align-items: center;
  }
}

