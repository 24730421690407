.how {
  display: flex;
  background-color: #ffffff;
  font-family: "Neue Montreal", Helvetica, Arial, sans-serif;
  letter-spacing: 0.06rem;
  color: black;
}

.how__box {
  padding: 70px 0;
  margin: 0 auto;
  width: 75%;
  z-index: 2;
}

.how__to__buy {
  font-size: 45px;
  font-weight: 600;
}

.how__here {
  font-size: 16px;
  margin: 20px 0;
  color: #302f2f;
  letter-spacing: 0.06rem;
}

.how__step {
  display: flex;
}

.how__step_box {
  width: 60%;
}

.step__text {
  margin-bottom: 20px;
}

.step__one {
  display: flex;
}

.one__number {
  color: #d4d4d4;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  border-radius: 100%;
  background-color:  rgb(63, 114, 136);
}

.one__text{
  width: 70%;
}

.one__text {
  margin: 0 0 0 30px;
}

.one__text__name {
  color: rgb(53, 53, 53);
  font-size: 26px;
}

.one__text__desk {
  color: #050505;
  font-size: 16px;
  margin: 14px 0 0 0;
  line-height: 1.5;
  z-index: 2;
}

.how__img-squareb {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  z-index: 1;

  & img {
    position: relative;
  }
}

.how__img-squareg {
  position: absolute;
  display: flex;
  align-self: flex-end;
  z-index: 1;
}

@media screen and (max-width: 900px) {
  .how__step_box {
    width: 100%;
  }
  .step__widget {
    display: none;
  }

  .step__one {
    width: 100%;
  }

  .one__text__name {
    width: 100%;
  }

  .one__text__desk {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .how__img-squareb {
    opacity: 0.7;

    & img {
      width: 100px;
    }
  }

  .how__img-squareg img{
    opacity: 0.7;
    width: 100px;
  }

  .one__number {
    font-size: 19px;
    height: 40px;
    width: 40px;
  }
}
@media screen and (max-width: 350px) {
  .step__one {
    flex-direction: column;
  }

  .one__text {
    width: 100%;
    align-items: center;
    margin: 30px 0;
  }
}

