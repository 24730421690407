.social {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  & a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 17px 10px 17px;
  }
}

.social__logo {
  margin: 0 15px;
}

.social__logo:hover {
  cursor: pointer;
  background-color: white;
}

.bscscan,
.telegram,
.twitter {
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
  border: 1px solid rgba(78, 108, 172, 0.6);
  border-radius: 3px;
  transition-duration: 0.2s;
  margin: 0 5px;
}

.bscscan {
  border: none;
}

.bscscan:hover,
.telegram:hover,
.twitter:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.twitter {
  background-image: url(images/white_twitter_bird.png);
}

.telegram {
  background-image: url(images/telegram.png);
}

@media screen and (max-width: 350px) {
  .social {
    flex-direction: column;
    align-items: center;

    & a {
      margin-bottom: 15px;
    }
  }
}
