.first {
  //background-image: url("../image/bg_mountain3.jpg");
  display: flex;
  justify-content: center;
  //background: linear-gradient(180deg, #010213 -5.85%, #4C2EB6 68.94%, #2E1D74 113.01%);
  background: linear-gradient(
    180deg,
    #057091 -5.85%,
    rgb(71, 176, 202) 50.94%,
    rgb(2, 65, 73) 113.01%
  );
  //background: linear-gradient(130deg, rgba(305,69,44) 0%, rgba(66,66,78,1) 35%, rgb(47, 86, 103) 10%);
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top: 0.1px solid rgb(254, 254, 255);
}

.text__power {
  display: flex;
  flex: content;
  height: 100%;
  margin: 50px 0 0 0;
  padding: 0 0 50px 0;
  font-family: 'Neue Montreal', Helvetica, Arial, sans-serif;
}

.first_text {
  width: 100%;
  z-index: 2;
}

.first_img {
  position: absolute;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: 85%;
  z-index: 1;
}

.feiht__true {
  color: white;
  font-size: 16px;
  display: inline-block;
  padding: 10px 20px;
  margin-bottom: 40px;
  letter-spacing: 0.08rem;
  background: rgba(205, 215, 226, 0.2);
  border-radius: 6px;
  backdrop-filter: blur(3px);
}

.part {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.participal {
  max-width: 400px;
  height: 250px;
  background: rgba(205, 215, 226, 0.2);
  border-radius: 7px;
  padding: 20px 25px;
  backdrop-filter: blur(3px);
}

.participal__text {
  color: white;
  font-size: 16px;
  margin-bottom: 40px;
  letter-spacing: 0.06rem;
  line-height: 1.6;
}

.participal__box {
  display: flex;
  flex: content;
}

.participal__button {
  padding: 10px 20px;
  color: rgb(8, 8, 8);
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  background-color: white;
  letter-spacing: 0.05rem;
  transition-duration: 0.4s;
  text-decoration: none;

  &:hover {
    color: #070707;
    background: rgba(242, 243, 245, 0.3);
  }
}

.participal__learn__more {
  display: flex;
  flex: content;
  margin: 8px 10px 5px 30px;
  padding: 3px 0 0 0;
  color: white;
  text-decoration: none;
  letter-spacing: 0.02rem;

  & .learn {
    font-size: 14px;
    letter-spacing: 0.06rem;
    transition-duration: 0.4s;
  }

  & a {
    text-decoration: none;
    color: white;
  }

  & a:hover {
    color: #fced1d;
    cursor: pointer;
  }
}

.learn__arrow {
  margin: 0 0 0 10px;
  height: 14px;
  width: 16px;
}

.first-img {
  display: none;
}

.first-img-bg {
  position: absolute;
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: flex-end;
  width: 100%;
  z-index: 1;
}

@media screen and (max-width: 1920px) {
  .chart-img {
    width: 35%;
  }
}

@media screen and (max-width: 1366px) {
  .chart-img {
    width: 40%;
  }
}

@media screen and (max-width: 1024px) {
  .first-img-bg {
    display: none;
  }
  .first-img {
    margin-bottom: 30px;
    display: flex;
    justify-content: flex-end;
  }

  .chart-img {
    width: 50%;
  }

  .part {
    flex-direction: column;
    align-items: center;
  }

  .first-img {
    justify-content: center;
    order: -1;
  }

  .participal {
    height: 230px;
  }
}

@media screen and (max-width: 768px) {
  .participal__text {
    font-size: 14px;
  }

  .feiht__true {
    font-size: 14px;
    letter-spacing: 0.05rem;
  }

  .participal {
    height: 200px;
  }

  .chart-img {
    width: 60%;
  }
}

@media screen and (max-width: 650px) {
  .chart-img {
    width: 65%;
  }
}

@media screen and (max-width: 450px) {
  .participal__box {
    flex-direction: column;
  }

  .participal__button {
    font-size: 13px;
  }

  .learn {
    font-size: 12px;
  }

  .participal__text {
    margin-bottom: 20px;
  }

  .participal__learn__more {
    margin: 20px 0;
  }
}
