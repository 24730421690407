.header {
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
    height: 70px;
    top: 1px; 
    width: 100%;
    z-index: 100;  
    background: rgba(82, 108, 131, 0.2);
    border-radius: 70px;
    font-family: "Neue Montreal", Helvetica, Arial, sans-serif;
    //position: fixed;
}

.navigation {
    display: flex;
    align-items: center;
}

.nav__item {
    margin: 10px 20px;
    font-size: 14px;
    color: white;
    letter-spacing: 0.06rem;
    transition-duration: 0.4s;
}
.nav__link :hover {
    color: #0cc90c;
}

.nav__item_a a{
    margin: 10px 20px;
    text-decoration: none;
    font-size: 14px;
    color: white;
    letter-spacing: 0.06rem;
    transition-duration: 0.4s;
}

.logo__name {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.logo {
    width: 32px;
    height: 32px;
    background-image: url(../../image/logo.png);
    background-repeat: no-repeat;
}

.company {
    margin: auto;
    padding: 0 15px;
    color: white;
    letter-spacing: 0.06rem;
}

.languages {
    margin: auto 0;
    font-size: 20px;
    color: white;
    letter-spacing: 0.06rem;
    transition-duration: 0.4s;
}

.languages:hover {
    color: #0cc90c;
}

@media screen and (max-width: 940px) {
    .nav__link {
        display: none;
    }
}

@media screen and (max-width: 300px) {
    .company {
        display: none;
    }
}