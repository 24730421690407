.sidebar {
  display: none;
  position: absolute;
  width: 100%;
  height: 100vh;
  font-family: "Neue Montreal", Helvetica, Arial, sans-serif;
  letter-spacing: 0.08rem;
  z-index: 101;
  //animation-duration: 100ms;
  animation-timing-function: ease-in-out;
  animation-name: basket;
}

@keyframes basket {
  0% {
      right: -25%;
  }

  50% {
      right: 0%;
  }
}

.sidebar__box {
  display: flex;
}

.left__side {
  width: 100%;
  height: 100vh;
  opacity: 0.8;
  background-color: rgb(197, 197, 197);
}

.right__side {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 100vh;
  background-color: #2E1D74;
}

.right__close {
  font-size: 20px;
  padding: 10px;

  &:hover {
    cursor: pointer;
  }
}

.right__side__links {
  display: flex;
  flex-direction: column;
}

.nav__mob {
  padding: 15px 0;
  text-align: center;

  &:hover{
    color: green;
    background-color: rgb(148, 197, 148);
  }

  & a {
    color: white;
    font-size: 20px;
    font-weight: 700;
    text-decoration: none;
  }
}

.favbar {
  color: white;
  display: none;
  padding: 0 4px;

  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 940px) {
  .favbar {
      display: block;
  }
}

@media screen and (max-width: 500px) {
  .left__side {
      display: none;
  }

  .right__side {
    width: 100%;
  }
}

