.road__map {
    display: flex;
    justify-content: center;
    font-family: "Neue Montreal", Helvetica, Arial, sans-serif;
}

.road__box {
    margin: 0 auto;
    padding: 40px 0;
    width: 75%;
}

.road__name {
    width: 100%;
    text-align: center;
    color: black;
    font-size: 45px;
    font-weight: 700;
}

.road__phases {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 40px 0;
}

.road__ph {
    display: flex;
    flex-direction: column;
    margin: 40px 0 0px 0;
    width: 500px;
    padding: 10px 20px;
    letter-spacing: 0.04rem;
    line-height: 1.5;
    border-radius: 5px;
    border-top-right-radius: 60px;
    border-bottom-right-radius: 30px;
    background-color: rgb(180, 180, 180);
    transition-duration: 0.4s;
    box-shadow: 5px 34px 16px -10px rgba(160, 160, 160, 0.9);

    &:hover {
        background-color: rgba(180, 180, 180, 0.8);
    }
}

.phase__one {
    margin: 9px 0 20px 0;
    font-weight: 600;
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 25px;
}

.phase__punkt {
    font-size: 20px;
    margin: 10px 0;
    color: white;
}

.diagramm {
    font-weight: 700;
    color: rgb(3, 185, 3);
    font-size: 22px;
}

.road__img-torus {
    position: absolute;
    width: 100%;
    object-fit: cover;

    & img {
        transform: rotate(-180deg);
    }
}

.road__img-rectangle {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-self: flex-end;
    width: 100%;
    padding: 0 0 0 25px;
    margin-bottom: 30px;
}

.road__img-rectangle2 {
    position: absolute;
    display: flex;
    width: 100%;
    padding: 0 25px 0 0;
    justify-content: flex-end;
    align-self: center;
}


@media screen and (max-width: 724px) {
    .road__name {
        font-size: 35px;
    }

    .road__img-torus img{
        width: 200px;
    }

    .road__ph {
        width: 100%;
        min-width: 150px;
    }
}

@media screen and (max-width: 400px) {
    .road__name {
        font-size: 25px;
    }

    .road__img-torus img{
        width: 100px;
    }
    .diagramm {
        font-size: 20px;
    }
}