.show__logo {
  display: flex;
  justify-content: center;
  margin-top: 150px;
  font-family: "Neue Montreal", Helvetica, Arial, sans-serif;
  letter-spacing: 0.05rem;
  line-height: 1.5;
}

.show__alt {
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-size: 20px;
}