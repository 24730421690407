.two {
  width: 100%;
  padding-top: 50px;
  background: linear-gradient(130deg, rgba(305,69,44) 0%, rgba(66,66,78,1) 35%, rgb(47, 86, 103) 10%);
  border-top-left-radius: 200px;
  border-top-right-radius: 1px;
  font-family: "Neue Montreal", Helvetica, Arial, sans-serif;
}

.two__owner {
  margin: 0 auto;
  width: 75%;
  padding-bottom: 50px;
}

.two__about {
  text-align: center;
 
  font-size: 45px;
  font-weight: 600;
  margin: 40px auto;
  color: white;
  letter-spacing: 0.08rem;
}

.two__tokens {
  display: flex;
  justify-content: space-between;
}

.one__box {
  padding: 20px 20px;
  width: 300px;
  letter-spacing: 0.05rem;
  border-radius: 7px;
  background: linear-gradient(108.46deg, rgba(255, 255, 255, 0.28) 0%, rgba(255, 255, 255, 0.14) 100%);
  border: 0.1px solid rgb(255, 255, 255, 0.8);
}

.one__box_teams {
  color: white;
  font-size: 25px;
}

.one__box_teams__one {
  font-size: 28px;
  font-weight: 600;
  color:  rgb(245, 182, 89);
}

.one__box_text {
  color: #ffffff;
  margin-top: 28px;
  font-size: 20px;
  line-height: 1.8;
  
}

.one__box_text__one {
  color: rgb(245, 182, 89);
  margin-top: 28px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.8;
}

.two__faiht {
  margin: 50px 0 30px 0;
  padding: 20px 20px;
  border-radius: 7px;
  background: linear-gradient(108.46deg, rgba(255, 255, 255, 0.28) 0%, rgba(255, 255, 255, 0.14) 100%);
  border: 0.1px solid rgb(255, 255, 255, 0.8);
}

.two__faiht__true {
  color: white;
  font-size: 25px;
  margin: 20px 0;

}

.two__faiht__text {
  color: #ffffff;
  margin-top: 28px;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: 0.03rem;
}


@media screen and (max-width: 1220px) {
  .two__tokens {
    flex-direction: column;
  }

  .one__box {
    width: 100%;
    margin-top: 30px;
    min-width: 160px;
  }

  .two__faiht {
    margin: 50px 0 50px 0;
  }
}

