.footer {
  margin: 15px;
  padding: 50px 0 80px 0;
  background-color: rgb(22, 21, 29);
  font-family: 'Neue Montreal', Helvetica, Arial, sans-serif;
}

.footer__box__all {
  display: flex;
}

.footer__box {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 75%;
}

.footer__logo__box {
  display: flex;
  width: 80%;
}

.footer__logo {
  width: 50px;
  height: 50px;
  background-image: url(../image/logo.png);
  background-repeat: no-repeat;
}

.logo__text__name {
  color: white;
  font-size: 20px;
  letter-spacing: 0.04rem;
  height: 50px;
  padding-top: 12px;
}

.footer__tok {
  color: white;
  margin: 0 0 20px 0;
  font-size: 15px;
  letter-spacing: 0.04rem;
}

.footer__tok__t {
  margin: 18px 0 0 0;
  font-size: 14px;
  letter-spacing: 0.04rem;
  text-decoration: none;
}

.footer__tok__t a {
  color: rgb(196, 196, 196);
  text-decoration: none;
  transition-duration: 0.3s;
}

.footer__tok__t a:hover {
  color: rgb(255, 198, 112);
}

.footer__tok__non {
  color: rgb(121, 119, 119);
}

.footer__email {
  font-size: 12px;
  color: rgb(196, 196, 196);
  letter-spacing: 0.04rem;
  margin: 20px 0 0 0;
}

.footer__line {
  margin: 30px 0;
  border-top: 0.2px solid rgb(168, 168, 168);
}

.footer__contact {
  display: flex;
  justify-content: space-between;
}

.contact__corp,
.privacy {
  font-size: 12px;
  color: rgb(196, 196, 196);
  letter-spacing: 0.04rem;
}

@media screen and (max-width: 350px) {
  .footer {
    padding-bottom: 70px;
  }
  .contact__corp {
    width: 70%;
    line-height: 1.6;
  }
}
