* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 0 15px 0;
}

body::-webkit-scrollbar {
  width: 12px;               /* ширина всей полосы прокрутки */
}

body::-webkit-scrollbar-track {
  background: orange;        /* цвет зоны отслеживания */
}

body::-webkit-scrollbar-thumb {
  background-color: blue;    /* цвет бегунка */
  border-radius: 20px;       /* округлось бегунка */
  border: 3px solid orange;  /* отступ вокруг бегунка */
}

body {
  scrollbar-width: thin;          /* в Firefox "auto" или "thin" */
  scrollbar-color: blue orange;   /* цвет бегунка и зоны отслеживания */
}