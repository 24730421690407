.three {
  background-image: url('../image/bg__tokens.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  outline: none;
  background-position: 100% 100%;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 150px;
  margin-top: -1px;
  font-family: 'Neue Montreal', Helvetica, Arial, sans-serif;
}

#copy__active {
  display: none;
  flex: content;
  align-items: center;
  position: fixed;
  font-size: 14px;
  right: 10px;
  top: 20px;
  width: 250px;
  height: 60px;
  border: 1px sold black;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: #ffffff;
  letter-spacing: 0.08rem;
  z-index: 5;
}

.copy__box {
  width: 10px;
  height: 60px;
  margin-right: 15px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: #0cc90c;
}

.three__box {
  margin: 0 auto;
  width: 75%;
  padding: 0 0 120px 0;
}

.three__katalog {
  text-align: center;
  font-size: 45px;
  font-weight: 600;
  margin: 40px 0 70px 0;
  padding-top: 50px;
  color: white;
  letter-spacing: 0.08rem;
}

.three__tokens {
  display: flex;
  justify-content: space-between;
}

.tok__one {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 20px 0 0 0;
  border-radius: 5px;
  backdrop-filter: blur(3px);
  background: rgba(71, 67, 67, 0.9);
  border-radius: 15px;
  transition-duration: 0.4s;
  box-shadow: 5px 34px 16px -10px rgba(0, 0, 0, 0.9);

  &:hover {
    background: rgba(71, 67, 67, 0.5);
  }
}

.tok__name {
  display: flex;
  justify-content: space-between;
  font-size: 28px;
  margin: 15px;
  font-weight: 600;
  color: gold;
  letter-spacing: 0.08rem;
}

.tok__name__name {
  display: flex;
  align-items: center;
}

.tok__logo {
  display: flex;
  justify-content: center;
  margin: 10px 0;

  & img {
    width: 90%;
  }
}

.tok__logo__small {
  text-align: center;
  width: 100%;

  & img {
    margin: 10px 0 7px 0;
  }
}

.tok__religion {
  margin: 15px 0;
  font-size: 30px;
  text-align: center;
  color: white;
  letter-spacing: 0.08rem;
  font-weight: 600;
}

.tok__text {
  margin: 15px 0;
  font-size: 13px;
  padding: 0 20px;
  color: rgb(212, 212, 212);
  letter-spacing: 0.06rem;
}

.tok__line {
  padding: 20px 20px 40px 20px;
  background-color: #3b3737;
  border-radius: 15px;

  &:hover {
    background-color: rgba(59, 55, 55, 0.3);
  }
}

.tok__character {
  display: flex;
  flex: content;
  font-weight: 500;
  justify-content: space-between;
  margin: 8px 0 0 0;
}

.soc {
  margin-top: 10px;
}

.tok__about,
.tok__namber {
  margin: 15px 0 0 0;
  font-size: 13px;
  color: rgb(230, 230, 230);
  letter-spacing: 0.06rem;
}

.contract {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(252, 248, 30);
  &:hover {
    cursor: pointer;
  }
}

.ampty {
  height: 32px;
  &:hover {
    cursor: auto;
  }
}

.img__copy {
  &:hover {
    cursor: pointer;
  }
}

.img__copy__check {
  display: none;
  width: 32px;
  height: 32px;
}

.tok__buttons {
  display: flex;
  flex: content;
  justify-content: space-between;
  margin: 70px 0 0 0;
}

.button__learn {
  display: flex;
  padding: 9px 0;
  color: rgb(233, 232, 232);
  text-decoration: none;
  font-size: 15px;
}

.button__learn__text {
  transition-duration: 0.4s;

  & a {
    font-size: 13px;
    text-decoration: none;
    color: rgb(255, 255, 255);
    transition-duration: 0.4s;
  }

  & a:hover {
    color: #0cc90c;
  }
}

.img__more__arrow {
  margin-left: 8px;
  margin-top: 2px;
  height: 12px;
  width: 12px;
}

@media screen and (max-width: 1300px) {
  .three__tokens {
    flex-direction: column;
    align-items: center;
  }

  .tok__one {
    width: 400px;
    margin-bottom: 50px;
  }

  .three {
    object-fit: cover;
  }
}

@media screen and (max-width: 900px) {
  .three__box {
    width: 85%;
  }

  .tok__one {
    margin-bottom: 60px;
  }

  .tok__about,
  .tok__namber {
    font-size: 20px;
  }
}

@media screen and (max-width: 550px) {
  .tok__one {
    width: 95%;
  }

  .three__box {
    width: 93%;
  }

  .tok__about,
  .tok__namber {
    font-size: 20px;
  }
}

@media screen and (max-width: 330px) {
  .tok__buttons {
    flex-direction: column;
    align-items: center;
  }

  .button__learn {
    margin-top: 30px;
  }
}
