.tokenomics {
  display: flex;
  justify-content: center;
  font-family: "Neue Montreal", Helvetica, Arial, sans-serif;
  border-bottom: 1px solid rgba(196, 196, 196, 0.7);
  width: 100%;
}

.tokenomics__box {
  width: 85%;
  background-color: rgba(66,66,78,1);
  border-radius: 20px;
  margin: 0 0 100px 0;
  padding: 40px 0 80px 0;
 
}

.teks__name {
  text-align: center;
  width: 100%;
  font-size: 61px;
  padding: 10px 0 60px 0;
  color: rgb(230, 238, 245);
  font-family: "Righteous", Sans-serif;
  font-weight: 600;
}

.teks__descriprion {
  display: flex;
  width: 100%;
}

.teks__descriprion__left {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
}

.teks__descriprion__safu {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 30%;
  font-size: 46px;
  font-weight: 700;
  color: rgba(0, 196, 196, 0.8);
  padding: 20px;
}

.teks__descriprion__safu img {
  max-width: 250px;
}

.teks__descriprion__safu__text {
  font-size: 46px;
  font-weight: 700;
  color: rgba(0, 196, 196, 0.8);
  margin: 30px 0 10px 0;
  width: 100%;
}

.token__bokss {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  width: 33%;
}

.soply__up {
  color: rgb(0, 196, 196);
  font-weight: 700;
  font-size: 40px;
}

.soply__down {
  color: white;
  font-size: 16px;
  margin-top: 15px;
}

.soply__up-c {
  font-weight: 700;
  margin: 7px 0;
  color: rgb(248, 212, 6);
  font-size: 30px;
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 1200px) { 
  .teks__descriprion__safu img {
    max-width: 200px;
  }
}

@media screen and (max-width: 1000px) { 
  .teks__descriprion__safu img {
    max-width: 160px;
  }
}


@media screen and (max-width: 800px) {
  .token__bokss {
    width: 50%;
  }
  
  .soply__up-c {
    font-size: 20px;
  } 
  .soply__up {
    font-size: 30px;
  }
}

@media screen and (max-width: 724px) {
  .tokenomics__box {
    width: 98%; 
    display: flex;
    flex-direction: column;
  }

  .teks__descriprion {
    flex-direction: column;
  }

  .token__bokss {
    width: 33%;
  }

  .teks__descriprion__left {
    width: 100%;
    
  }
  .teks__descriprion__safu {
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;
    order: -1;
  }
}

@media screen and (max-width: 500px) {
  .teks__name {
    font-size: 40px;
  }

  .teks__descriprion__safu {
    font-size: 27px;
    margin-bottom: 50px;
  }

  .token__bokss {
    width: 50%;
    margin: 25px 0 15px 0;
  }
  
  .soply__up {
    font-size: 27px;
  }
}

@media screen and (max-width: 355px) {
  .teks__name {
    font-size: 25px;
  }

  .teks__descriprion__safu {
    font-size: 22px;
    margin-bottom: 30px;
  }

  .token__bokss {
    width: 100%;
    margin: 15px 0 15px 0;
  }
}

@media screen and (max-width: 300px) { 
  .teks__descriprion__safu img {
    max-width: 100px;
  }
}