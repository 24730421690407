.button-up {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 70px;
  right: 40px;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  font-size: 20px;
  color: white;
  background-color: rgb(108, 233, 5);
  transition-duration:  0.4s;
  z-index: 102;

  &:hover {
    background-color: rgb(125, 216, 6);
    cursor: pointer;
  }

  &:active {
    background-color: rgb(80, 192, 5);
  }
}

@media screen and (max-width: 550px) {
  .button-up{
    bottom: 30px;
    right: 30px;
  }
}